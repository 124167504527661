import { forwardRef } from 'react'
import { classnames } from 'tailwindcss-classnames'

const Input = (
  { type = 'text', label, description, className, isInvalid, ...restProps },
  ref
) => {
  return (
    <label
      className={classnames('inline-flex flex-col items-start', className)}
    >
      <input
        type={type}
        className={classnames(
          'order-3 p-1 w-60 border-2 border-x-0 border-t-0 focus:ring-0',
          isInvalid
            ? 'text-red-500 focus:text-red-500 border-current'
            : 'text-[#0E0E0E] border-primary-800 focus:border-primary-600'
        )}
        {...restProps}
        ref={ref}
      />
      <span className="order-1 mb-[10px] w-full text-xl font-bold text-primary-800">
        {label}
      </span>
      {description && (
        <span className="order-2 mb-5 text-base font-medium text-primary-800">
          {description}
        </span>
      )}
    </label>
  )
}

const _Input = forwardRef(Input)

export { _Input as Input }

import { useRef, useState, forwardRef, useEffect } from 'react'
import Image from 'next/image'
import { useForm, FormProvider } from 'react-hook-form'
import { classnames } from 'tailwindcss-classnames'
import useInView from 'react-cool-inview'
import { DevTool } from '@hookform/devtools'
import { ErrorMessage } from '@hookform/error-message'
import { Menu, Transition } from '@headlessui/react'
// import { google, ics } from 'calendar-link'
import NextHeadSeo from 'next-head-seo'
import {
  captureException,
  captureMessage,
  setUser,
  setContext,
} from '@sentry/nextjs'
import { Input } from '@/components/elements/Input'
import { Radio, RadioGroup } from '@/components/elements/Radio'
import { Textarea } from '@/components/elements/Textarea'
import { useValidateField } from '@/utils/react-hook-form/useValidateField'
import { assignRef } from '@/utils/assignRef'
import CalendarIcon from '@/icons/calendar.svg'
import LineIcon from '@/icons/line.svg'
import LoveIcon from '@/icons/love.svg'

const SurveyBlock = (
  { className, children, firstBlock, ...restProps },
  ref
) => {
  const [scrollDirection, setScrollDirection] = useState()
  const { observe } = useInView({
    threshold: 0.01,
    onEnter: ({ scrollDirection }) => {
      setScrollDirection(scrollDirection.vertical)
    },
  })
  return (
    <div
      className={classnames(
        'overflow-auto h-[var(--init100vh,100vh)] snap-center snap-always',
        className
      )}
      ref={(e) => {
        assignRef(observe, e)
        assignRef(ref, e)
      }}
      {...restProps}
    >
      <div
        className={classnames('h-full', {
          'opacity-0': !firstBlock,
          [{
            down: 'animate-fadeInDown',
            up: 'animate-fadeInUp',
          }[scrollDirection]]: !!scrollDirection,
        })}
      >
        {children}
      </div>
    </div>
  )
}

const ConfirmButton = ({ className, ...restProps }) => (
  <button
    className={classnames(
      'py-1 px-4 text-base text-white bg-gradient-to-r from-primary-500 to-primary-600 rounded-lg',
      className
    )}
    {...restProps}
  />
)

const ForwardedSurveyBlock = forwardRef(SurveyBlock)

export function SurveyPage() {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      attendWedding: '',
      attendWeddingBanquet: '',
      childrenSeatNumber: '0',
      driveToTheLin: '',
      email: '',
      name: '',
      nickname: '',
      phone: '',
      receiveInvitation: '',
      relationship: '',
      vegetarianNumber: '0',
      weddingAttendance: '0',
      weddingBanquetAttendance: '0',
      whisper: '',
    },
  })
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    control,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, dirtyFields, isSubmitSuccessful },
  } = methods
  const { validateField } = useValidateField()
  const refs = {
    lazy: {
      axios: useRef(),
      scrollIntoView: useRef(),
    },
    hero: useRef(),
    name: useRef(),
    nickname: useRef(),
    relationship: useRef(),
    attendWedding: useRef(),
    weddingAttendance: useRef(),
    weddingAttendanceInput: useRef(),
    attendWeddingBanquet: useRef(),
    weddingBanquetAttendance: useRef(),
    weddingBanquetAttendanceInput: useRef(),
    vegetarianNumber: useRef(),
    vegetarianNumberInput: useRef(),
    childrenSeatNumber: useRef(),
    driveToTheLin: useRef(),
    phone: useRef(),
    phoneInput: useRef(),
    receiveInvitation: useRef(),
    email: useRef(),
    emailInput: useRef(),
    whisper: useRef(),
    finish: useRef(),
  }
  const relationshipOptions = [
    '👰🏻‍️新娘爸爸親戚',
    '👰🏻‍️新娘爸爸朋友',
    '👰🏻‍️新娘媽媽親戚',
    '👰🏻‍️新娘媽媽朋友',
    '👰🏻‍️新娘同學/朋友/同事',
    '👰🏻‍️新生命小組教會（新娘朋友）',
    '🤵🏻新郎爸爸親戚',
    '🤵🏻新郎爸爸朋友',
    '🤵🏻新郎媽媽親戚',
    '🤵🏻新郎媽媽朋友',
    '🤵🏻新郎同學/朋友/同事',
    '🤵🏻新生命小組教會（新郎朋友）',
    '🤵🏻美福教會（新郎朋友）',
  ]
  const attendWeddingOptions = [
    { value: '1', text: '出席，絕對到場' },
    { value: '0', text: '無法出席，祝你們幸福美滿' },
  ]
  const weddingAttendanceOptions = [
    { value: '1', text: '1人' },
    { value: '2', text: '2人' },
    { value: '3', text: '3人' },
    { value: '4', text: '更多' },
  ]
  /* const attendWeddingBanquetOptions = [
    { value: '1', text: '出席，絕對到場' },
    { value: '0', text: '無法出席，祝你們幸福美滿' },
  ]
  const weddingBanquetAttendanceOptions = [
    { value: '1', text: '1人' },
    { value: '2', text: '2人' },
    { value: '3', text: '3人' },
    { value: '4', text: '更多' },
  ]
  const vegetarianNumberOptions = [
    { value: '0', text: '不需要' },
    { value: '1', text: '1人' },
    { value: '2', text: '2人' },
    { value: '3', text: '更多' },
  ]
  const childrenSeatNumberOptions = [
    { value: '0', text: '不需要' },
    { value: '1', text: '1張' },
    { value: '2', text: '2張' },
  ]
  const driveToTheLinOptions = [
    { value: '1', text: '開車前往' },
    { value: '0', text: '其他交通方式' },
  ] */
  const receiveInvitationOptions = [
    { value: '1', text: '好！請寄給我' },
    { value: '0', text: '不用喔' },
  ]
  const event = {
    title: '証頡佳頴 J&E Wedding 4/9',
    description: '進入LINE@追蹤我們的婚禮資訊\nhttps://lin.ee/CLd3Y7A',
    start: '2022-04-09T09:00:00+0800',
    duration: [6, 'hour'],
    url: 'https://lin.ee/CLd3Y7A',
  }
  const [isFinish, setIsFinish] = useState(false)
  const axiosRef = refs.lazy.axios
  const scrollIntoViewRef = refs.lazy.scrollIntoView
  const nameValue = watch('name')

  const google = () => {
    return 'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220409T010000Z%2F20220409T070000Z&details=%E9%80%B2%E5%85%A5LINE%40%E8%BF%BD%E8%B9%A4%E6%88%91%E5%80%91%E7%9A%84%E5%A9%9A%E7%A6%AE%E8%B3%87%E8%A8%8A%0Ahttps%3A%2F%2Flin.ee%2FCLd3Y7A&text=%E8%A8%BC%E9%A0%A1%E4%BD%B3%E9%A0%B4%20J%26E%20Wedding%204%2F9'
  }
  const ics = () => {
    return 'data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https%3A%2F%2Flin.ee%2FCLd3Y7A%0ADTSTART:20220409T010000Z%0ADTEND:20220409T070000Z%0ASUMMARY:%E8%A8%BC%E9%A0%A1%E4%BD%B3%E9%A0%B4%20J%26E%20Wedding%204%2F9%0ADESCRIPTION:%E9%80%B2%E5%85%A5LINE%40%E8%BF%BD%E8%B9%A4%E6%88%91%E5%80%91%E7%9A%84%E5%A9%9A%E7%A6%AE%E8%B3%87%E8%A8%8A%5Cnhttps%3A%2F%2Flin.ee%2FCLd3Y7A%0AEND:VEVENT%0AEND:VCALENDAR%0A'
  }
  const focusElement = (ref) => () => {
    const load = async () => {
      refs.lazy.scrollIntoView.current = await import(
        'seamless-scroll-polyfill'
      ).then((mod) => mod.scrollIntoView)
    }
    const focus = () => {
      if (ref?.current) {
        scrollIntoViewRef.current(ref.current, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
    if (!scrollIntoViewRef.current) {
      load().then(() => {
        focus()
      })
    } else {
      focus()
    }
  }
  const onSubmit = async (data) => {
    setContext('form data', data)
    captureMessage('submit')
    const load = async () => {
      refs.lazy.axios.current = (await import('axios')).default
    }
    const submit = () => {
      return axiosRef
        .current({
          url: '/api/survey',
          method: 'post',
          data,
        })
        .then((response) => {
          focusElement(refs.finish)()
          setTimeout(() => {
            setIsFinish(true)
          }, 1500)
        })
        .catch((error) => {
          captureException(error)
          setTimeout(() => {
            focusElement(refs.whisper)()
            setError('whisper', {
              type: 'system',
              message: '好像出了小狀況，重試看看',
            })
          }, 1000)
          setTimeout(() => {
            clearErrors()
          }, 4000)
        })
    }
    if (!axiosRef.current) {
      await load()
        .then(() => {
          submit()
        })
        .catch((error) => {
          captureException(error)
        })
    } else {
      await submit()
    }
  }

  useEffect(() => {
    setUser({ username: nameValue })
  }, [nameValue])
  return (
    <div>
      <NextHeadSeo canonical={process.env.NEXT_PUBLIC_SITE_URL + '/welcome'} />
      <div className="overflow-y-scroll h-[var(--init100vh,100vh)] scroll-smooth snap-y snap-mandatory">
        {!isFinish && (
          <FormProvider {...methods}>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <ForwardedSurveyBlock firstBlock ref={refs.hero}>
                <div className="flex flex-col min-h-full lg:flex-row">
                  <div className="flex-initial h-[40%] lg:flex-1 lg:order-2 lg:h-auto">
                    <div className="lg:hidden text-[0]">
                      <Image
                        src="/images/DSC07516.jpg"
                        alt=""
                        width={600}
                        height={400}
                        layout="responsive"
                        priority
                      />
                    </div>
                    <div className="hidden relative w-full h-full lg:block text-[0]">
                      <Image
                        src="/images/DSC07269.jpg"
                        alt=""
                        layout="fill"
                        objectFit="cover"
                        priority
                      />
                    </div>
                  </div>
                  <div className="container flex flex-1 py-5 lg:order-1 lg:justify-center lg:items-center">
                    <div className="flex flex-col items-start lg:max-w-[52%]">
                      <article className="text-base text-justify text-primary-800 whitespace-pre-line">
                        <h1 className="hidden">証頡佳頴 J&E Wedding 4/9</h1>
                        <p className="text-xl font-bold">
                          {`Hi, 各位親愛的家人與朋友們，我們將在 2022.4.9 (六) 結婚囉！🥳`}
                        </p>
                        <br />
                        <p>
                          {`我們會在台中美福教會舉行結婚禮拜，邀請大家一起來見證我們生命中重要的時刻，另外我們也會在林酒店進行宴客，歡迎你來與我們一起同樂！`}
                        </p>
                      </article>
                      <ConfirmButton
                        type="button"
                        className="mt-7"
                        onClick={focusElement(refs.name)}
                      >
                        開始填寫
                      </ConfirmButton>
                    </div>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              <ForwardedSurveyBlock firstBlock ref={refs.name}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <Input
                      label="請問你的姓名"
                      autoComplete="name"
                      {...(() => {
                        const { ref, ...restProps } = register('name', {
                          validate: validateField({
                            required: true,
                          }),
                        })
                        return {
                          ref: (e) => {
                            assignRef(ref, e)
                          },
                          ...restProps,
                        }
                      })()}
                      isInvalid={!!errors.name}
                    />
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="name"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('name')
                        if (!result) return
                        focusElement(refs.nickname)()
                      }}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              <ForwardedSurveyBlock firstBlock ref={refs.nickname}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <Input
                      label={`嗨，${
                        watch('name') || '_________'
                      }，很開心在這裡遇到你！如果我們比較常用其他方式稱呼你，也請告訴我們`}
                      placeholder="a.k.a."
                      enterKeyHint="done"
                      {...(() => {
                        const { ref, ...restProps } = register('nickname')
                        return {
                          ref: (e) => {
                            assignRef(ref, e)
                          },
                          ...restProps,
                        }
                      })()}
                      isInvalid={!!errors.nickname}
                    />
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="nickname"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={focusElement(refs.relationship)}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              <ForwardedSurveyBlock ref={refs.relationship}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <RadioGroup
                      label={`${
                        watch('nickname') ? `${watch('nickname')}，` : ''
                      }想確認你跟我們的關係`}
                    >
                      {relationshipOptions.map((option, index) => (
                        <Radio
                          value={option}
                          isInvalid={!!errors.relationship}
                          key={option}
                          {...(() => {
                            const { ref, onChange, ...restProps } = register(
                              'relationship',
                              {
                                validate: validateField({
                                  required: true,
                                }),
                              }
                            )
                            return {
                              ref: (e) => {
                                assignRef(ref, e)
                              },
                              onChange: (e) => {
                                onChange(e).then(() => {
                                  focusElement(refs.attendWedding)()
                                })
                              },
                              ...restProps,
                            }
                          })()}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="relationship"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('relationship')
                        if (!result) return
                        focusElement(refs.attendWedding)()
                      }}
                    >
                      確定
                    </ConfirmButton>
                    <div className="sticky bottom-0 pt-8 w-full bg-gradient-to-b from-white/0 to-white" />
                  </div>
                </div>
              </ForwardedSurveyBlock>
              <ForwardedSurveyBlock ref={refs.attendWedding}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <RadioGroup
                      label="想邀請你參與我們的結婚禮拜"
                      description={
                        <>
                          時間｜
                          <time dateTime="2022-04-09T09:30:00+08:00">
                            2022.04.09 上午 9:30（9:00 入場）
                          </time>
                          <br />
                          地點｜台中美福教會
                        </>
                      }
                    >
                      {attendWeddingOptions.map((option, index) => (
                        <Radio
                          value={option.value}
                          isInvalid={!!errors.attendWedding}
                          key={option.value}
                          {...(() => {
                            const { ref, onChange, ...restProps } = register(
                              'attendWedding',
                              {
                                validate: validateField({
                                  required: true,
                                }),
                              }
                            )
                            return {
                              ref: (e) => {
                                assignRef(ref, e)
                              },
                              onChange: (e) => {
                                onChange(e).then(() => {
                                  if (+getValues('attendWedding')) {
                                    focusElement(refs.weddingAttendance)()
                                  } else {
                                    focusElement(refs.phone)()
                                  }
                                })
                              },
                              ...restProps,
                            }
                          })()}
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="attendWedding"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('attendWedding')
                        if (!result) return
                        if (+getValues('attendWedding')) {
                          focusElement(refs.weddingAttendance)()
                        } else {
                          focusElement(refs.phone)()
                        }
                      }}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              {watch('attendWedding') === '1' && (
                <ForwardedSurveyBlock ref={refs.weddingAttendance}>
                  <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                    <div className="col-span-12" />
                    <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                      {dirtyFields.weddingAttendance ? (
                        <Input
                          label="共幾人出席結婚禮拜"
                          type="number"
                          inputMode="numeric"
                          defaultValue={getValues('weddingAttendance')}
                          min={1}
                          enterKeyHint="done"
                          {...(() => {
                            const { ref, ...restProps } = register(
                              'weddingAttendance',
                              {
                                validate: validateField({
                                  required: true,
                                }),
                                min: {
                                  value: 1,
                                  message: '數字錯誤',
                                },
                              }
                            )
                            return {
                              ref: (e) => {
                                assignRef(ref, e)
                                assignRef(refs.weddingAttendanceInput, e)
                              },
                              ...restProps,
                            }
                          })()}
                          isInvalid={!!errors.weddingAttendance}
                        />
                      ) : (
                        <RadioGroup label="共幾人出席結婚禮拜">
                          {weddingAttendanceOptions.map((option) => (
                            <Radio
                              value={option.value}
                              defaultChecked={
                                option.value === getValues('weddingAttendance')
                              }
                              isInvalid={!!errors.weddingAttendance}
                              key={option.value}
                              {...(() => {
                                const { ref, onChange, ...restProps } =
                                  register('weddingAttendance', {
                                    validate: validateField({
                                      required: true,
                                    }),
                                  })
                                return {
                                  ref: (e) => {
                                    assignRef(ref, e)
                                  },
                                  onChange: (e) => {
                                    onChange(e).then(() => {
                                      if (
                                        Number(getValues('weddingAttendance')) >
                                        3
                                      )
                                        return
                                      focusElement(refs.phone)()
                                    })
                                  },
                                  ...restProps,
                                }
                              })()}
                            >
                              {option.text}
                            </Radio>
                          ))}
                        </RadioGroup>
                      )}
                      <ErrorMessage
                        as="div"
                        className="text-red-500"
                        errors={errors}
                        name="weddingAttendance"
                      />
                      <ConfirmButton
                        type="button"
                        className="mt-7"
                        onClick={async () => {
                          const result = await trigger('weddingAttendance')
                          if (!result) return
                          focusElement(refs.phone)()
                        }}
                      >
                        確定
                      </ConfirmButton>
                    </div>
                  </div>
                </ForwardedSurveyBlock>
              )}
              {/* <ForwardedSurveyBlock ref={refs.attendWeddingBanquet}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <RadioGroup
                      label="也歡迎你來婚宴，與我們同樂"
                      description={
                        <>
                          時間｜
                          <time dateTime="2022-04-09T09:30:00+08:00">
                            2022.04.09 上午 12:00（11:00 入場）
                          </time>
                          <br />
                          地點｜The Lin Hotel 林酒店
                        </>
                      }
                    >
                      {attendWeddingBanquetOptions.map((option, index) => (
                        <Radio
                          value={option.value}
                          isInvalid={!!errors.attendWeddingBanquet}
                          key={option.value}
                          {...(() => {
                            const { ref, onChange, ...restProps } = register(
                              'attendWeddingBanquet',
                              {
                                validate: validateField({
                                  required: true,
                                }),
                              }
                            )
                            return {
                              ref: (e) => {
                                assignRef(ref, e)
                              },
                              onChange: (e) => {
                                onChange(e).then(() => {
                                  if (+getValues('attendWeddingBanquet')) {
                                    focusElement(
                                      refs.weddingBanquetAttendance
                                    )()
                                  } else {
                                    focusElement(refs.phone)()
                                  }
                                })
                              },
                              ...restProps,
                            }
                          })()}
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="attendWeddingBanquet"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('attendWeddingBanquet')
                        if (!result) return
                        if (+getValues('attendWeddingBanquet')) {
                          focusElement(refs.weddingBanquetAttendance)()
                        } else {
                          focusElement(refs.phone)()
                        }
                      }}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              {watch('attendWeddingBanquet') === '1' && (
                <>
                  <ForwardedSurveyBlock ref={refs.weddingBanquetAttendance}>
                    <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                      <div className="col-span-12" />
                      <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                        {dirtyFields.weddingBanquetAttendance ? (
                          <Input
                            label="共幾人出席婚宴"
                            type="number"
                            inputMode="numeric"
                            defaultValue={getValues('weddingBanquetAttendance')}
                            min={1}
                            enterKeyHint="done"
                            {...(() => {
                              const { ref, ...restProps } = register(
                                'weddingBanquetAttendance',
                                {
                                  validate: validateField({
                                    required: true,
                                  }),
                                  min: {
                                    value: 1,
                                    message: '數字錯誤',
                                  },
                                }
                              )
                              return {
                                ref: (e) => {
                                  assignRef(ref, e)
                                  assignRef(
                                    refs.weddingBanquetAttendanceInput,
                                    e
                                  )
                                },
                                ...restProps,
                              }
                            })()}
                            isInvalid={!!errors.weddingBanquetAttendance}
                          />
                        ) : (
                          <RadioGroup label="共幾人出席婚宴">
                            {weddingBanquetAttendanceOptions.map((option) => (
                              <Radio
                                value={option.value}
                                defaultChecked={
                                  option.value ===
                                  getValues('weddingBanquetAttendance')
                                }
                                isInvalid={!!errors.weddingBanquetAttendance}
                                key={option.value}
                                {...(() => {
                                  const { ref, onChange, ...restProps } =
                                    register('weddingBanquetAttendance', {
                                      validate: validateField({
                                        required: true,
                                      }),
                                    })
                                  return {
                                    ref: (e) => {
                                      assignRef(ref, e)
                                    },
                                    onChange: (e) => {
                                      onChange(e).then(() => {
                                        if (
                                          Number(
                                            getValues(
                                              'weddingBanquetAttendance'
                                            )
                                          ) > 3
                                        )
                                          return
                                        focusElement(refs.vegetarianNumber)()
                                      })
                                    },
                                    ...restProps,
                                  }
                                })()}
                              >
                                {option.text}
                              </Radio>
                            ))}
                          </RadioGroup>
                        )}
                        <ErrorMessage
                          as="div"
                          className="text-red-500"
                          errors={errors}
                          name="weddingBanquetAttendance"
                        />
                        <ConfirmButton
                          type="button"
                          className="mt-7"
                          onClick={async () => {
                            const result = await trigger(
                              'weddingBanquetAttendance'
                            )
                            if (!result) return
                            focusElement(refs.vegetarianNumber)()
                          }}
                        >
                          確定
                        </ConfirmButton>
                      </div>
                    </div>
                  </ForwardedSurveyBlock>
                  <ForwardedSurveyBlock ref={refs.vegetarianNumber}>
                    <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                      <div className="col-span-12" />
                      <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                        {dirtyFields.vegetarianNumber ? (
                          <Input
                            label="共幾人需要素食"
                            type="number"
                            inputMode="numeric"
                            defaultValue={getValues('vegetarianNumber')}
                            min={0}
                            enterKeyHint="done"
                            {...(() => {
                              const { ref, ...restProps } = register(
                                'vegetarianNumber',
                                {
                                  validate: validateField({
                                    required: true,
                                  }),
                                  min: {
                                    value: 0,
                                    message: '數字錯誤',
                                  },
                                }
                              )
                              return {
                                ref: (e) => {
                                  assignRef(ref, e)
                                  assignRef(refs.vegetarianNumberInput, e)
                                },
                                ...restProps,
                              }
                            })()}
                            isInvalid={!!errors.vegetarianNumber}
                          />
                        ) : (
                          <RadioGroup label="共幾人需要素食">
                            {vegetarianNumberOptions.map((option, index) => (
                              <Radio
                                value={option.value}
                                defaultChecked={
                                  option.value === getValues('vegetarianNumber')
                                }
                                isInvalid={!!errors.vegetarianNumber}
                                key={option.value}
                                {...(() => {
                                  const { ref, onChange, ...restProps } =
                                    register('vegetarianNumber', {
                                      validate: validateField({
                                        required: true,
                                      }),
                                    })
                                  return {
                                    ref: (e) => {
                                      assignRef(ref, e)
                                    },
                                    onChange: (e) => {
                                      onChange(e).then(() => {
                                        if (
                                          Number(
                                            getValues('vegetarianNumber')
                                          ) > 2
                                        )
                                          return
                                        focusElement(refs.childrenSeatNumber)()
                                      })
                                    },
                                    ...restProps,
                                  }
                                })()}
                              >
                                {option.text}
                              </Radio>
                            ))}
                          </RadioGroup>
                        )}
                        <ErrorMessage
                          as="div"
                          className="text-red-500"
                          errors={errors}
                          name="vegetarianNumber"
                        />
                        <ConfirmButton
                          type="button"
                          className="mt-7"
                          onClick={async () => {
                            const result = await trigger('vegetarianNumber')
                            if (!result) return
                            focusElement(refs.childrenSeatNumber)()
                          }}
                        >
                          確定
                        </ConfirmButton>
                      </div>
                    </div>
                  </ForwardedSurveyBlock>
                  <ForwardedSurveyBlock ref={refs.childrenSeatNumber}>
                    <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                      <div className="col-span-12" />
                      <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                        <RadioGroup label="需要幾張兒童座椅">
                          {childrenSeatNumberOptions.map((option, index) => (
                            <Radio
                              value={option.value}
                              isInvalid={!!errors.childrenSeatNumber}
                              key={option.value}
                              {...(() => {
                                const { ref, onChange, ...restProps } =
                                  register('childrenSeatNumber', {
                                    validate: validateField({
                                      required: true,
                                    }),
                                  })
                                return {
                                  ref: (e) => {
                                    assignRef(ref, e)
                                  },
                                  onChange: (e) => {
                                    onChange(e).then(() => {
                                      focusElement(refs.driveToTheLin)()
                                    })
                                  },
                                  ...restProps,
                                }
                              })()}
                            >
                              {option.text}
                            </Radio>
                          ))}
                        </RadioGroup>
                        <ErrorMessage
                          as="div"
                          className="text-red-500"
                          errors={errors}
                          name="childrenSeatNumber"
                        />
                        <ConfirmButton
                          type="button"
                          className="mt-7"
                          onClick={async () => {
                            const result = await trigger('childrenSeatNumber')
                            if (!result) return
                            focusElement(refs.driveToTheLin)()
                          }}
                        >
                          確定
                        </ConfirmButton>
                      </div>
                    </div>
                  </ForwardedSurveyBlock>
                  <ForwardedSurveyBlock ref={refs.driveToTheLin}>
                    <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                      <div className="col-span-12" />
                      <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                        <RadioGroup label="如何前往林酒店">
                          {driveToTheLinOptions.map((option, index) => (
                            <Radio
                              value={option.value}
                              isInvalid={!!errors.driveToTheLin}
                              key={option.value}
                              {...(() => {
                                const { ref, onChange, ...restProps } =
                                  register('driveToTheLin', {
                                    validate: validateField({
                                      required: true,
                                    }),
                                  })
                                return {
                                  ref: (e) => {
                                    assignRef(ref, e)
                                  },
                                  onChange: (e) => {
                                    onChange(e).then(() => {
                                      focusElement(refs.phone)()
                                    })
                                  },
                                  ...restProps,
                                }
                              })()}
                            >
                              {option.text}
                            </Radio>
                          ))}
                        </RadioGroup>
                        <ErrorMessage
                          as="div"
                          className="text-red-500"
                          errors={errors}
                          name="driveToTheLin"
                        />
                        <ConfirmButton
                          type="button"
                          className="mt-7"
                          onClick={async () => {
                            const result = await trigger('driveToTheLin')
                            if (!result) return
                            focusElement(refs.phone)()
                          }}
                        >
                          確定
                        </ConfirmButton>
                      </div>
                    </div>
                  </ForwardedSurveyBlock>
                </>
              )} */}
              <ForwardedSurveyBlock ref={refs.phone}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <Input
                      label="請留下手機號碼"
                      type="tel"
                      inputMode="tel"
                      autoComplete="tel"
                      enterKeyHint="done"
                      {...(() => {
                        const { ref, ...restProps } = register('phone', {
                          validate: validateField({
                            required: true,
                            mobilePhone: true,
                          }),
                          setValueAs: (value) => value.replaceAll(/\D/g, ''),
                        })
                        return {
                          ref: (e) => {
                            assignRef(ref, e)
                            assignRef(refs.phoneInput, e)
                          },
                          ...restProps,
                        }
                      })()}
                      isInvalid={!!errors.phone}
                    />
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="phone"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('phone')
                        if (!result) return
                        focusElement(refs.receiveInvitation)()
                      }}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              <ForwardedSurveyBlock ref={refs.receiveInvitation}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <RadioGroup label="是否想收到電子喜帖">
                      {receiveInvitationOptions.map((option, index) => (
                        <Radio
                          value={option.value}
                          isInvalid={!!errors.receiveInvitation}
                          key={option.value}
                          {...(() => {
                            const { ref, onChange, ...restProps } = register(
                              'receiveInvitation',
                              {
                                validate: validateField({
                                  required: true,
                                }),
                              }
                            )
                            return {
                              ref: (e) => {
                                assignRef(ref, e)
                              },
                              onChange: (e) => {
                                onChange(e).then(() => {
                                  if (+getValues('receiveInvitation')) {
                                    focusElement(refs.email)()
                                  } else {
                                    focusElement(refs.whisper)()
                                  }
                                })
                              },
                              ...restProps,
                            }
                          })()}
                        >
                          {option.text}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="receiveInvitation"
                    />
                    <ConfirmButton
                      type="button"
                      className="mt-7"
                      onClick={async () => {
                        const result = await trigger('receiveInvitation')
                        if (!result) return
                        if (+getValues('receiveInvitation')) {
                          focusElement(refs.email)()
                        } else {
                          focusElement(refs.whisper)()
                        }
                      }}
                    >
                      確定
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
              {watch('receiveInvitation') === '1' && (
                <ForwardedSurveyBlock ref={refs.email}>
                  <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                    <div className="col-span-12" />
                    <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                      <Input
                        label="電子喜帖寄送地址(Email)"
                        type="email"
                        inputMode="email"
                        autoComplete="email"
                        enterKeyHint="done"
                        className="w-full"
                        {...(() => {
                          const { ref, ...restProps } = register('email', {
                            validate: validateField({
                              required: true,
                              email: true,
                            }),
                            setValueAs: (value) => value.trim(),
                          })
                          return {
                            ref: (e) => {
                              assignRef(ref, e)
                              assignRef(refs.emailInput, e)
                            },
                            ...restProps,
                          }
                        })()}
                        isInvalid={!!errors.email}
                      />
                      <ErrorMessage
                        as="div"
                        className="text-red-500"
                        errors={errors}
                        name="email"
                      />
                      <ConfirmButton
                        type="button"
                        className="mt-7"
                        onClick={async () => {
                          const result = await trigger('email')
                          if (!result) return
                          focusElement(refs.whisper)()
                        }}
                      >
                        確定
                      </ConfirmButton>
                    </div>
                  </div>
                </ForwardedSurveyBlock>
              )}
              <ForwardedSurveyBlock ref={refs.whisper}>
                <div className="container grid grid-cols-12 grid-rows-[minmax(0,40%)_1fr] content-start py-5 h-full">
                  <div className="col-span-12" />
                  <div className="flex flex-col col-span-12 items-start lg:col-span-6 lg:col-start-4">
                    <Textarea
                      classname="w-full"
                      label="有什麼話想和我們說嗎？"
                      rows={3}
                      {...(() => {
                        const { ref, ...restProps } = register('whisper')
                        return {
                          ref: (e) => {
                            assignRef(ref, e)
                          },
                          ...restProps,
                        }
                      })()}
                      isInvalid={!!errors.whisper}
                    />
                    <ErrorMessage
                      as="div"
                      className="text-red-500"
                      errors={errors}
                      name="whisper"
                    />
                    <ConfirmButton
                      type="submit"
                      className="mt-7 w-40 disabled:opacity-70"
                      disabled={
                        isSubmitting ||
                        Object.keys(errors).length > 0 ||
                        isSubmitSuccessful
                      }
                    >
                      {isSubmitting || isSubmitSuccessful ? (
                        <span
                          className="inline-flex items-center max-h-[1em] before:content-[attr(data-content)]"
                          data-content={`\u200b`}
                        >
                          <svg
                            className="inline-block w-5 h-5 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        '沒問題了！送出'
                      )}
                    </ConfirmButton>
                  </div>
                </div>
              </ForwardedSurveyBlock>
            </form>
            <DevTool control={control} />
          </FormProvider>
        )}
        {isSubmitSuccessful && (
          <ForwardedSurveyBlock ref={refs.finish}>
            <div className="flex overflow-hidden relative flex-col h-full bg-[#f2fdfe]">
              <div className="absolute bottom-0 mx-[-25%] mt-auto w-[150%] lg:mx-0 lg:w-full lg:h-full">
                <div className="aspect-[3/2] lg:aspect-auto lg:absolute lg:inset-0 text-[0]">
                  <Image
                    src="/images/DSC07563.jpg"
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    objectPosition="bottom"
                  />
                </div>
              </div>
              <div className="container relative pt-16 text-center">
                <div className="inline-block overflow-hidden h-[88px]">
                  <div
                    className={classnames(
                      'transition-transform origin-bottom',
                      {
                        'translate-y-full': !isFinish,
                        'translate-y-0': isFinish,
                      }
                    )}
                  >
                    <span>
                      <LoveIcon className="inline-block w-7 h-10 fill-secondary-800" />
                    </span>
                    <p className="mt-5 text-xl font-bold text-secondary-800">
                      謝謝，我們收到了
                    </p>
                  </div>
                </div>
                <h2 className="mt-4 text-lg font-medium text-secondary-800">
                  歡迎加入LINE@追蹤我們的婚禮資訊
                </h2>
                <ul className="inline-flex mt-5 space-x-4">
                  <li>
                    <Menu
                      as="div"
                      className="inline-block relative mr-1 text-left"
                    >
                      {({ open }) => (
                        <>
                          <Menu.Button
                            className={classnames(
                              'inline-flex justify-center items-center w-36 h-9 text-base font-medium',
                              'rounded-lg border-2 border-current transition-colors',
                              {
                                'text-secondary-700 hover:text-secondary-100 hover:bg-secondary-700 hover:border-secondary-700':
                                  !open,
                                'text-secondary-100 bg-secondary-700 border-secondary-700':
                                  open,
                              }
                            )}
                          >
                            <span
                              className="inline-flex items-center max-h-[1em] before:content-[attr(data-content)]"
                              data-content={`\u200b`}
                            >
                              <CalendarIcon className="mr-1 w-5 h-5 fill-current" />
                            </span>
                            加入行事曆
                          </Menu.Button>
                          <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Menu.Items className="absolute left-0 mt-2 w-52 bg-white rounded-md focus:outline-none ring-1 ring-black/50 shadow-lg origin-top-right">
                              <div className="p-1">
                                <Menu.Item>
                                  <a
                                    href={google(event)}
                                    className="flex items-center p-2 w-full text-sm text-secondary-800 hover:text-white hover:bg-secondary-800 rounded-md transition-colors"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    Google 日曆
                                  </a>
                                </Menu.Item>
                                <Menu.Item>
                                  <a
                                    href={ics(event)}
                                    className="flex items-center p-2 w-full text-sm text-secondary-800 hover:text-white hover:bg-secondary-800 rounded-md transition-colors"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    ics 行事曆（Safari 開啟）
                                  </a>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </li>
                  <li>
                    <a
                      href="https://lin.ee/ScfBQqE"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex justify-center items-center w-36 h-9 text-base font-medium text-secondary-100 bg-secondary-700 hover:bg-secondary-800 rounded-lg transition-colors"
                    >
                      <span
                        className="inline-flex items-center max-h-[1em] before:content-[attr(data-content)]"
                        data-content={`\u200b`}
                      >
                        <LineIcon className="mr-1 w-5 h-5 fill-current" />
                      </span>
                      加入LINE@
                    </a>
                  </li>
                </ul>
                <p className="mt-5 text-lg font-medium text-secondary-800">
                  LINE@上除了婚禮的時間及地點資訊，如果有任何建議也能直接留言給我們唷
                  😘
                </p>
              </div>
            </div>
          </ForwardedSurveyBlock>
        )}
      </div>
    </div>
  )
}

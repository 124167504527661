import { useCallback } from 'react'
// import { isValid as isValidDate } from 'date-fns'
import debounce from 'debounce-promise'

// https://emailregex.com/
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// Minimum 6 characters and maximum 20 characters, at least one letter and one number:
const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/

const requiredValidate = (value) => {
  return value?.length > 0
}

const phoneValidate = async (value, { isMobile } = {}) => {
  const PhoneNumber = (await import('awesome-phonenumber')).default
  const pn = new PhoneNumber(value, 'tw')
  return pn.isValid() && (isMobile ? pn.isMobile() : true)
}

export const useValidateField = ({ passwordRegex = PASSWORD_REGEX } = {}) => {
  const validateField = useCallback((options, debounceOptions) => {
    const validate = async (value) => {
      const { email, date, phone, mobilePhone, password, required } = options
      if (required) {
        const isValid = requiredValidate(value)
        if (!isValid) return '必填'
      } else {
        // 非必填，空欄位不驗證
        const isValid = value?.length === 0
        if (isValid) return true
      }
      if (email) {
        const isValid = EMAIL_REGEX.test(value)
        if (!isValid) return '信箱格式錯誤'
      }
      if (date) {
        // const isValid = isValidDate(new Date(value))
        // if (!isValid) return '日期格式錯誤'
      }
      if (mobilePhone) {
        const isValid = await phoneValidate(value, { isMobile: true })
        if (!isValid) return '手機格式錯誤'
      }
      if (phone) {
        const isValid = await phoneValidate(value)
        if (!isValid) return '電話格式錯誤'
      }
      if (password) {
        const isValid = passwordRegex.test(value)
        if (!isValid) return '密碼格式錯誤'
      }
      return true
    }
    if (debounceOptions?.length > 0) {
      return debounce(validate, ...debounceOptions)
    }
    return validate
  }, [])
  return {
    validateField,
  }
}

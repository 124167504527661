import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Header } from '@/components/modules/Header'
import { use100vh } from '@/utils/use100vh'

export const Common = ({ children }) => {
  const router = useRouter()
  const { locale } = router
  const height = use100vh()

  useEffect(() => {
    if (height) {
      const root = document.querySelector(':root')
      root.style.setProperty('--100vh', `${height}px`)
      if (!getComputedStyle(root).getPropertyValue('--init100vh')) {
        root.style.setProperty('--init100vh', `${height}px`)
      }
    }
  }, [height])
  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta name="theme-color" content="#597696" />
        <meta httpEquiv="content-language" content={locale} />
      </Head>
      <Header />
      {children}
    </div>
  )
}

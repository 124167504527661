import { forwardRef } from 'react'
import { classnames } from 'tailwindcss-classnames'
import style from './Radio.module.css'

const RadioGroup = ({
  label,
  description,
  className,
  children,
  ...restProps
}) => {
  return (
    <div
      className={classnames('flex flex-col items-start', className)}
      {...restProps}
    >
      <span className="flex-none mb-[10px] w-full text-xl font-bold text-primary-800">
        {label}
      </span>
      {description && (
        <span className="mb-5 text-base font-medium text-primary-800">
          {description}
        </span>
      )}
      <div className="flex flex-col flex-wrap mt-1 space-y-3">{children}</div>
    </div>
  )
}

const Radio = ({ children, className, isInvalid, ...restProps }, ref) => {
  return (
    <label
      className={classnames(
        'inline-flex relative py-1 px-2',
        'bg-primary-200 rounded border-primary-500 border-none',
        className
      )}
    >
      <input
        className={classnames(
          style.radio,
          'peer mt-1 mr-2 w-5 h-5 rounded-full border-current transition duration-200',
          isInvalid ? 'text-red-500' : 'text-primary-800 focus:ring-primary-800'
        )}
        {...restProps}
        type="radio"
        ref={ref}
      />
      <span
        className={classnames(
          'text-lg',
          isInvalid
            ? 'text-red-500'
            : 'text-primary-900 peer-checked:text-primary-800'
        )}
      >
        {children}
      </span>
    </label>
  )
}

const _Radio = forwardRef(Radio)

export { _Radio as Radio, RadioGroup }

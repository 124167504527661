import { forwardRef, useEffect, useRef } from 'react'
import { classnames } from 'tailwindcss-classnames'
import { assignRef } from '@/utils/assignRef'

const Textarea = ({ label, classname, isInvalid, ...restProps }, ref) => {
  const innerRef = useRef()
  useEffect(() => {
    const onInput = () => {
      innerRef.current.style.height = 'auto'
      innerRef.current.style.height = innerRef.current.scrollHeight + 'px'
    }
    innerRef.current.setAttribute(
      'style',
      'height:' + innerRef.current.scrollHeight + 'px;overflow-y:hidden;'
    )
    innerRef.current.addEventListener('input', onInput, false)
  }, [])
  return (
    <label className={classnames('inline-flex flex-col', classname)}>
      <textarea
        className={classnames(
          'order-2 py-2 px-3 w-full bg-primary-200 rounded border-current focus:border-current border-none focus:ring-0',
          isInvalid ? 'text-red-500 focus:text-red-500' : 'text-primary-800'
        )}
        {...restProps}
        ref={(e) => {
          assignRef(ref, e)
          assignRef(innerRef, e)
        }}
      />
      <span className="order-1 mb-2 w-full text-xl font-bold text-primary-800">
        {label}
      </span>
    </label>
  )
}

const _Textarea = forwardRef(Textarea)

export { _Textarea as Textarea }
